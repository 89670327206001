import React, { useState } from "react";
import { FastField, getIn } from "formik";
import { Form, Radio } from "semantic-ui-react";
import { FieldLabel } from "react-invenio-forms";
import { PIDField } from "react-invenio-deposit";

// Constants
const RADIO_DOI_NO = "radio_doi_no";
const RADIO_DOI_YES = "radio_doi_yes";
const PROVIDER_EXTERNAL = "external";


function DOIInputField(props) {
  // Props
  const { error, fieldPath, form, visible, value } = props;

  // Specific to this component
  {/* TODO if contribute: reintroduce translation */ }
  const placeholder = "Copy/paste your existing DOI here..."

  // derived
  // Can't change an internal (managed) DOI
  const provider = value.provider || PROVIDER_EXTERNAL;  // to be safe
  const isEnabled = visible && provider === PROVIDER_EXTERNAL;

  // Updates
  function handleChange(e, {value}) {
    const newDOI = {
      identifier: value,
      provider: PROVIDER_EXTERNAL, // in keeping with InvenioRDM decision
    };

    form.setFieldValue(fieldPath, newDOI);

  }

  return (
    <Form.Field width={8} error={error}>
      <Form.Input
        aria-label="External DOI"
        className={{ "hide": !visible }}
        disabled={!isEnabled}
        onChange={handleChange}
        value={value.identifier}
        placeholder={placeholder}
        width={16}
        error={error}
      />
    </Form.Field>
  );
}


function getFieldErrors(form, fieldPath) {
  return (
    getIn(form.errors, fieldPath, null) || getIn(form.initialErrors, fieldPath, null)
  );
};


function DOIField(props) {
  // State
  const [radioDOINo, setRadioDOINo] = useState(
    // We want the default to be "No DOI", but InvenioRDM gives us by default
    // `pids: {doi: {identifier: "", provider: "external"}}`
    // So, we assume that initial empty identifier means "No DOI" and adjust
    // the form accordingly. Note that this means that reloading a form that
    // had an empty identifier will reset it to "No DOI"... which is fine.
    // We are using initialValues to signal the intent of this code more
    // strongly.
    () => {
      const initialIdentifier = props.form.initialValues.pids.doi?.identifier;
      if (!initialIdentifier) {
        const { doi, ...otherPids } = props.form.initialValues.pids;
        props.form.setFieldValue("pids", otherPids);
        return true;
      } else {
        return false;
      }
    }
  );
  const [savedDOI, setSavedDOI] = useState(
    // Making sure that savedDOI has correct values for when they are set
    () => {
      const doi = props.form.initialValues.pids.doi || {};
      return {
        // Being very safe with whatever is given to us
        // On the right are the InvenioRDM default values
        ...doi,
        identifier: doi?.identifier || "",
        provider: doi?.provider || "external"
      }
    }
  );

  // Props
  const {
    // Injected by Formik
    form,
    // Passed by parent component
    pid,
    record,
  } = props;

  // Specific to this component
  const pidIcon = "barcode";
  const required = true;
  {/* TODO if contribute: reintroduce label translation */ }
  const helpText = "A DOI allows your upload to be easily and unambiguously cited. Example: '10.1234/foo.bar'. Upon publication, a DOI will be minted for you if you don't already have one.";

  // Derived
  const fieldPath = `pids.${pid.scheme}`;
  const fieldError = getFieldErrors(form, fieldPath);
  const radioDisabled = record.is_published === true;
  const doi = form.values.pids.doi || {};

  // Update
  function handleRadioChange(e, { value }) {
    // value is new value
    if (value === RADIO_DOI_NO) {
      setRadioDOINo(true);
      // Remove doi section from pids
      const { doi, ...otherPids } = form.values.pids || {};
      setSavedDOI(doi);
      form.setFieldValue("pids", otherPids);
    } else {
      setRadioDOINo(false);
      form.setFieldValue(fieldPath, savedDOI);
    }
  }

  // Render
  return (
    <>
      <Form.Field required={required} error={fieldError}>
        <FieldLabel htmlFor={fieldPath} icon={pidIcon} label={"Digital Object Identifier (DOI)"} />
      </Form.Field>

      <Form.Field>
        {/* TODO if contribute: reintroduce translation */}
        Do you already have a DOI for this upload?
      </Form.Field>

      <Form.Field width={2}>
        {/* TODO if contribute: reintroduce label translation */}
        <Radio
          id="doi-radio-no"
          label="No"
          name="radioGroup"
          value={RADIO_DOI_NO}
          disabled={radioDisabled}
          checked={radioDOINo}
          onChange={handleRadioChange}
        />
      </Form.Field>

      <Form.Group inline>
        {/* mb/mt-auto  provided by framework */}
        <Form.Field>
          {/* TODO if contribute: reintroduce label translation */}
          <Radio
            id="doi-radio-yes"
            label="Yes"
            name="radioGroup"
            value={RADIO_DOI_YES}
            disabled={radioDisabled}
            checked={!radioDOINo}
            onChange={handleRadioChange}
            width={4}
          />
        </Form.Field>
        <DOIInputField
          fieldPath={fieldPath}
          visible={!radioDOINo}
          value={doi}
          form={form}
          error={fieldError}
        />
      </Form.Group>

      {helpText && <label className="helptext">{helpText}</label>}
    </>
  );
}


function PIDFieldSelector(props) {
  // Props
  let { pid, record } = props;

  // Derived
  let fieldPath = `pids.${pid.scheme}`;

  // Render
  if (pid.scheme === "doi") {
    let componentProps = {
      pid,
      record,
    };
    return <FastField name={fieldPath} component={DOIField} {...componentProps} />;
  } else {
    // We return the original PIDField component
    // Based on PIDField definition and usage:
    // definition: https://github.com/inveniosoftware/react-invenio-deposit/blob/master/src/lib/components/Identifiers/PIDField.js#L342
    // usage: https://github.com/inveniosoftware/invenio-app-rdm/blob/master/invenio_app_rdm/theme/assets/semantic-ui/js/invenio_app_rdm/deposit/RDMDepositForm.js#L182
    // Because of how Overridable components work, the current props are all
    // the ones of the usage, so they can be passed down as-is.
    return <PIDField {...props} />;
  }
}


export const overriddenComponents = {
  "InvenioAppRdm.Deposit.PIDField.layout": PIDFieldSelector,
};
